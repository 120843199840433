<template>
  <div class="my_nft_page">
    <Frames/>
    <div class="nfts_all">
      <div class="nfts_top">
        <div class="nft_text">
          <div class="nft_text_a">TrustFi Booster</div>
          <div class="nft_text_b"> Built on Web 3.0</div>
          <div class="nft_text_c">TrustFi focuses on early crypto assets issuance, liquidity management, community
            activities and DAO governance to unlock the potential of DeFi
          </div>
          <div class="nft_text_d">
            <buy-tfi-btn />
            <div class="d_anniu_b"
                 @click="openUrl('https://docs.google.com/forms/d/e/1FAIpQLSca1CqJqUem0Wi_WPMYWaXMWwan8iB7ygp87wOsuoM1DGwtlg/viewform')">
              Apply for Boosting
            </div>
            <div class="d_anniu_c" @click="openUrl('https://solidity.finance/audits/TrustFiBooster/')">Audit Report
            </div>
          </div>
        </div>
        <div class="nft_img mobHide">
          <img src="../../assets/images/Group.png">
        </div>
      </div>
      <div class="sec_title">NFT’s</div>
      <div class="nft_list">

        <div class="nft_sec" v-for="(nft, index) in nftInfoArr" :key="index" v-show="nftInfoArr.length > 0">
          <div class="d1">
            <img class="img1" src="../../assets/images/nft/nft_bj.png"/>
            <div class="d2">
              <div class="d3">
                <img v-if="false" class="img2" src="../../assets/images/nft/Group_ma.png"/>
              </div>
              <div class="d4">
                <div class="d5">{{nft.nftName}} #{{nft.nftId}}</div>
                <div class="d6">Vote Round #{{nft.voteCast}}</div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <footer-v3/>
  </div>
</template>

<script>
  import Frames from "../../components/frames/Index"
  import { mixin1 } from "../../tools/mixins";
  import * as metaMaskTools from "../../tools/chain";
  import { BigNumberDiv } from "../../tools";
  import store from "../../store";
  import transfer from "../../tools/transfer";
  import BuyTfiBtn from "../../components/frames/BuyTfiBtn";
  import FooterV3 from "../../components/frames/FooterV3";

  export default {
    name: "Index",
    components: {FooterV3, BuyTfiBtn, Frames},
    mixins: [mixin1],
    data() {
      return {
        accounts: '',
        nftQueryLock: false,
        poolAddressArr: {
          // 测试
          // 'BSC': [
          //   '0x7809cB90470d68Cd9A64b153e7c02b3E0af6AF70',
          //   '0x7352e4A27274cC9c21A6F9B79e08E0255b764f86',
          //   '0xcde255a01767FE432880fF37178C1D696eaa9715'
          // ],
          // 'ETH': [],
          // 'AVAX': [
          //   '0xb923919fa6Df7675aa33069425aD42878BF39206'
          // ],
          // 'POLYGON': []

          // 正式
          'BSC': [
            '0x42cD297E40Ce1d9D0a1Fad59caAc3aD462d2bf2a',
            '0x953807813ee8eb31c234616b7f7dd07e0c6dd04a'
          ],
          'ETH': [],
          'AVAX': [],
          'POLYGON': []
        },
        nftInfoArr: []
      };
    },
    watch: {},
    created() {
      setTimeout(() => {
        this.onAccountsChanged();
      }, 1000)

      var that = this
      transfer.$on('tokenChainRefresh', function () {
        that.tokenChainRefresh()
      })
    },
    mounted() {
      if (localStorage.getItem('accounts')) {
        this.accounts = localStorage.getItem('accounts');
      }
      this.getMyNftAll()
    },
    beforeDestroy() {
    },
    methods: {
      openUrl(url) {
        window.open(url)
      },
      tokenChainRefresh() {
        this.accounts = ''
        this.getMyNftAll()
      },
      onRefresh(accounts) {
        this.$store.commit('setState', {
          accounts
        });
        localStorage.setItem('accounts', accounts);
        if (localStorage.getItem('accounts')) {
          this.accounts = localStorage.getItem('accounts');
        } else {
          this.accounts = '';
        }
        this.getMyNftAll()
      },
      refreshData(val) {
        if (val === undefined) {
          this.accounts = ''
        } else {
          this.accounts = val
        }
        this.getMyNftAll();
      },
      async getMyNftAll() {
        console.log('try nftQueryLock')
        if (this.nftQueryLock) {
          return;
        } else {
          console.log('nftQueryLock return')
          this.nftQueryLock = true;
        }
        console.log('nftQuery start')
        store.dispatch("getHomeData");
        try {
          this.nftInfoArr = [];
          if (!this.accounts) {
            this.nftQueryLock = false;
            console.log('nftQuery no account, return')
            return;
          }
          var tokenChainType = sessionStorage.getItem("tokenChainType") ? sessionStorage.getItem("tokenChainType") : 'BSC'
          for (let i = 0; i < this.poolAddressArr[tokenChainType].length; i++) {
            await metaMaskTools.queryMyNft(this.poolAddressArr[tokenChainType][i], this.accounts).then(async (data) => {
              console.log(`nftQuery query idx:${i}, res:`, data)
              if (parseInt(data[1].nftId) > 0) {
                let nftInfo = {
                  nftId: 0,
                  nftName: null,
                  user: null,
                  voteCast: 0
                }

                nftInfo.nftId = data[1].nftId;
                nftInfo.nftName = data[1].nftName;
                nftInfo.user = data[1].user;
                nftInfo.voteCast = BigNumberDiv(data[1].voteCast, 1e18);

                this.nftInfoArr.push(nftInfo);
              }
            });
          }
        } catch (e) {
          console.log('query NFT failed: ',e)
        }
        setTimeout(() => {
          this.nftQueryLock = false;
        }, 1000)
      }
    }
  }
</script>

<style scoped lang="less">
  .my_nft_page {
    width: 100%;
    height: 100%;
    min-width: 1080px;
    min-height: 730px;
    background-color: black;

    .nfts_all {
      zoom: 0.9;
      margin: 0 100px;

      /*width: inherit;*/
      /*min-width: 1080px;*/
      /*background-color: black;*/

      /*position: absolute;*/
      top: 100px;
      bottom: 0;
      right: 0;
      left: 0;
      min-width: 1080px;
      overflow-y: auto;
      overflow-x: hidden;

      .nfts_top {
        display: flex;

        .nft_text {
          color: white;
          margin: 50px 60px;

          .nft_text_a {
            font-family: Roboto;
            font-style: normal;
            font-weight: bold;
            font-size: 45px;
            line-height: 50px;
          }

          .nft_text_b {
            font-family: Roboto;
            font-style: normal;
            font-weight: bold;
            font-size: 45px;
            line-height: 50px;
            color: #FEE108;
          }

          .nft_text_c {
            margin: 15px 0;
            line-height: 30px;
            font-size: 20px;
          }

          .nft_text_d {
            max-width: 610px;
            color: black;
            font-weight: 600;
            display: flex;
            align-items: center;
            justify-content: space-around;

            .d_anniu_a {
              cursor: pointer;
              width: 190px;
              height: 45px;
              text-align: center;
              line-height: 45px;
              background: #3CD63C;
              border: 1px solid #83DB5C;
              box-sizing: border-box;
              box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
              border-radius: 10px;
            }

            .d_anniu_b {
              cursor: pointer;
              width: 190px;
              height: 45px;
              text-align: center;
              line-height: 45px;
              background: #FEE108;
              border: 1px solid #FEE108;
              box-sizing: border-box;
              box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
              border-radius: 10px;
            }

            .d_anniu_c {
              cursor: pointer;
              width: 190px;
              height: 45px;
              text-align: center;
              line-height: 45px;
              background: #40EFE5;
              border: 1px solid #40EFE5;
              box-sizing: border-box;
              box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
              border-radius: 10px;
            }
          }

          .nft_text_e {
            max-width: 610px;

            .e_num {
              color: #FEE108;
              font-size: 30px;
              font-weight: 600;
              margin-top: 15px;
            }

            .e_text {
              font-weight: 600;
            }

            display: flex;
            align-items: center;
            justify-content: space-around;
            text-align: center;

            .e_kuang_a {
              width: 190px;
              height: 100px;
              margin: 30px 10px 0 0;
              background: #131313;
              border: 4px solid #FEE108;
              box-sizing: border-box;
              border-radius: 13px;
            }

            .e_kuang_b {
              width: 190px;
              height: 100px;
              margin: 30px 10px 0 0;
              background: #131313;
              border: 4px solid #FEE108;
              box-sizing: border-box;
              border-radius: 13px;
            }

            .e_kuang_c {
              width: 190px;
              height: 100px;
              margin: 30px 0 0 0;
              background: #131313;
              border: 4px solid #FEE108;
              box-sizing: border-box;
              border-radius: 13px;
            }
          }
        }

        .nft_img {
          img {
            margin: 40px;
            width: 390px;
          }
        }
      }

      .nfts_info {
        border-top: 4px solid #FEE108;
        color: white;

        .info_tile {
          font-size: 45px;
          font-weight: 600;
          text-align: center;
          margin: 50px 0 20px 0;
        }

        .info_text {
          text-align: center;
          font-size: 20px;
          line-height: 30px;
          font-family: Roboto;
          font-style: normal;
          text-align: center;

          .info_text_a {
            margin: 10px 50px;
          }

          .info_text_b {
            margin: 30px 50px;
          }
        }

        .info_kong_jian {
          height: 50px;
          margin: 45px 0;

          .jian_anniu {
            text-align: center;
            line-height: 42px;
            font-weight: 600;
            color: white;
            float: right;
            display: flex;
            justify-content: space-evenly;
            width: 420px;
            margin: 10px;

            .anniu_select {
              color: black;
              background: #7FFF00 !important;
              border: 1px solid #7FFF00 !important;
              box-sizing: border-box !important;
              box-shadow: 0px 0px 18px #8BCC49 !important;
            }

            .anniu_live {
              cursor: pointer;
              width: 150px;
              height: 42px;
              background: #717171;
              border: 1px solid #6C6C6C;
              box-sizing: border-box;
              box-shadow: 0px 0px 18px #000000;
              border-radius: 10px;
            }

            .anniu_finished {
              cursor: pointer;
              width: 150px;
              height: 42px;
              background: #717171;
              border: 1px solid #6C6C6C;
              box-sizing: border-box;
              box-shadow: 0px 0px 18px #000000;
              border-radius: 10px;
            }
          }
        }

        .info_nfts {
          width: 100%;

          .nft_data {
            height: 280px;
            background: #000000;
            border: 3px solid #FEE108;
            box-sizing: border-box;
            box-shadow: 0px 0px 50px #000000;
            border-radius: 15px;
            margin: 20px 50px;
          }
        }
      }
    }

    .sec_title {
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 49px;
      line-height: 57px;
      color: #FFFFFF;
      height: 57px;
      margin: -25px 0 25px 0;
      padding: 0 60px;
    }

    .nft_list {
      padding: 0 60px;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      align-content: space-around;
      flex-direction: row;
    }

    .nft_sec {
      width: 298px;
      height: 390px;
      padding: 19px;
      background: #404040D9;
      box-sizing: border-box;
      border-radius: 15px;
      margin: 10px 30px 50px 0;

      .d1 {
        width: 260px;
        height: 355px;
        position: relative;
        z-index: 2;

        .img1 {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
        }
      }

      .d2 {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-bottom: 20px;
      }

      .d3 {
        padding-left: 10px;
        transform: translateY(5px);

        .img2 {
          width: 57px;
          height: 57px;
        }
      }

      .d4 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        color: #fff;
        padding-left: 10px;
      }

      .d5 {
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 21px;
        color : #FFFFFF;
        /*color: #21b526;*/
        /*background-color: black;*/
      }

      .d6 {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 15px;
        color : #FFFFFF;
        /*color: #21b526;*/
        /*background-color: black;*/
        margin-top: 10px;
      }
    }
  }

  @import '../../global.less';
</style>
